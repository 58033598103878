/**
 * @ComponentFor EPiServer.Core.XhtmlString
 */
import React from 'react';
import { Html, registerDecorator } from '@avensia/scope';
import Image from '../../Image';
import { Size } from '../../image-sizes';

registerDecorator('img', (item, i, props, componentProps) => {
  let size = Size.Large;
  if (props['data-size']) {
    size = props['data-size'];
  }
  if (componentProps.keepImageSizes === false) {
    if (props.width) {
      delete props.width;
    }
    if (props.height) {
      delete props.height;
    }
  }
  return <Image {...props} preset={size} />;
});

export default Html;
